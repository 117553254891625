import * as React from "react"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet"
import {Link} from "gatsby"
import Cookies from 'universal-cookie'
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Axios from "axios";

const TravelPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=travel_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - Marathon 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu lang={lang} changeLang={changeLang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>Reise</h1>

                    <h2>Fly</h2>
                    <p>
                        Det er flere flyselskaper som flyr til Athen fra Norge. SAS og Norwegian pleier dessuten å ha direkteruter dit på sommeren.
                    </p>

                    <p>
                        Om man også synes det er greit med mellomlanding så finnes det mange muligheter.
                        Vi anbefaler å bruke en søketjeneste som f.eks. <a href={"https:///momondo.no"} target={"_blank"}>momondo.no</a> for å finne det alternativet som passer best.
                    </p>

                    <p>
                        Det er stor etterspørsel etter feriereiser nå, så det er en god ide å være tidlig ute.
                    </p>

                    <h2>Fornyelse av pass</h2>
                    <p>
                        Det er veldig lange køer for fornyelse av pass i Norge for tiden, så det anbefales å sjekke utløp for pass og eventuelt bestille time for fornyelse.
                    </p>

                    <h2>Korona-restriksjoner</h2>
                    <p>
                        På nettsiden <a target={"_blank"} href={"https://travel.gov.gr"}>travel.gov.gr</a> ser man hva som gjelder til enhver tid hva angår reiser til Hellas.
                    </p>

                    <h2>Transport fra flyplassen</h2>
                    <p>
                        Det tar ca 45 minutter fra flyplassen til området bryllupet holdes i med taxi.
                        Taxi-apper som kan være nyttige: <strong>Beat</strong> og eventuelt <strong>Uber</strong>.
                    </p>
                    <p>
                        Det er også mulig å benytte seg av offentlig transport, men dette vil ta vesentlig lenger tid og medfører flere bussbytter.
                        En kan f.eks. benytte ruten X95 fra flyplassen til "Gefyra". Så buss 314 til "Rafina" og til slutt buss 310 i retning "Marathonas".
                    </p>
                </>
            }
            {lang == "en" &&
                <>
                    <h1>Travel</h1>

                    {/*<h2>By airplane</h2>
                    <p>
                        There are several airlines flying into Athens, depending on your city of departure. Especially during
                        summer there are many choices. We recommend using a airticket comparison site like
                        <a href={"https:///momondo.com"} target={"_blank"}>momondo.com</a> to find a suitable ticket.
                    </p>*/}

                    <h2>By car</h2>
                    <p>
                        For those of our guests arriving by car you can find the event venue by driving towards the city of Marathonas when coming from Athens.
                        Just before arriving at Marathonas follow the signs for "Schinias". As you are about to enter the national park
                        take a right hand turn and follow signs for "Karavi". After a few hunderes metres "<a target="_blank" href="https://g.page/galaziaaktibeach?share">Galazia Akti</a>" will be on your
                        left hand side.
                    </p>

                </>
            }

        </main>
    )
}

export default TravelPage
